@font-face {
    font-family: kristenITC;
    src: url(../../fonts/ITCKRIST.TTF);
} 
  
p {
    font-family: kristenITC;
}

@media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      align-items: center;
    }
  
    .about-sections-container {
      width: 100%;
    }
  }